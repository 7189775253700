import React from "react"
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import SignUpEmail from "../../../components/signUpEmail";
import img1 from "../../../images/marketing-services/social-media-marketing/social-media-marketing-img-1.svg";
import img2 from "../../../images/marketing-services/social-media-marketing/social-media-marketing-img-2.svg";
import img3 from "../../../images/marketing-services/social-media-marketing/social-media-marketing-img-3.svg";
import img4 from "../../../images/marketing-services/social-media-marketing/social-media-marketing-img-4.svg";
import AccordionComponent from "../../../components/AccordionComponent"


const smmPage = () => (
  <Layout css="socialMediaMarketingPageCss">
    <SEO title="Social Media Marketing Boston"
         description={"Our Boston-based Social Media Marketing Agency can help you master social media. Turn likes into leads with a social strategy maximized for success."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>SOCIAL MEDIA MARKETING </span>BOSTON</h1>
          <div className={"subtitle"}>
            <p>When it comes to digital marketing, it will not suffice to cater only to those who are within your
              business market. You must widen your reach to be as inclusive as possible, especially now that the
              competition is getting tougher day by day.</p>
            <p>Social media marketing in Boston is one of the most effective ways to promote your products to casual
              audiences. Two businesses may offer the same products and services, but no two social media marketing
              strategies should be the same. If you want to stand out of the crowd, take every opportunity to showcase
              your business in the most impactful way possible.</p>
            <p>Avantcy does not only formulate a social media marketing plan. We also ensure you will be using social
              media marketing in Boston that matches your company’s needs and target audience. Avantcy is also a Boston
              social media agency that offers budget-friendly services without compromising quality and results.</p>
          </div>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img2} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>WHY SOCIAL MEDIA MARKETING?</h2>
            <p className={"text-align-left"}>With your potential customers being on social media every day, our social
              media marketing in Boston will aim to use social media to drive up sales. Among the most prominent names
              in social media marketing companies in Boston, Avantcy builds brand awareness through active marketing on
              LinkedIn, Instagram, Twitter, Facebook, and more platforms.</p>
            <p className={"text-align-left"}>Platforms like Facebook, Instagram, Twitter, YouTube, etc., helped
              businesses develop today’s most efficient and convenient marketing tactics, especially when establishing a
              brand image.
              However, as this industry drastically introduced new features and algorithm changes, it may be hard for
              you to monitor them and instantly cope up with them.</p>
            <p className={"text-align-left"}>For instance, Snapchat was known for its disappearing and FOMO-inducing
              content a few years ago, but it didn’t appear appealing for similar features on other networks. Meanwhile,
              Instagram’s IG Story feature eventually emerged, where over 500 million people now use this feature per
              day.
              This incident only proves how you must be quick to adopt recent trends in social media.</p>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>WE'LL TAKE CARE OF IT</h2>
      </div>
      <h3 className={"subtitle"}>You are already busy running your business, would you still have time to review rapid
        changes regularly and pivot to add new strategies? With too many responsibilities on your hand,
        let our Boston social media company to lighten the burden for you. Besides keeping an eye out for the latest
        trends, below are some of the perks you could get as we join hands!</h3>
    </div>
    <div className={"container section-padding-wrapper"}>
      <AccordionComponent label="Enhanced Customer Insights">
        <p>Your customers’ insights are significant for a better understanding of their preferences, feedback, and
          suggestions for your business’s improvement. With social media, you can now oversee and monitor your
          business’s appeal to the public while gaining ideas on what you can do to make your business more
          favorable.</p>
      </AccordionComponent>
      <AccordionComponent label="Better Customer Service">
        <p>When it comes to responding to your customers’ inquiries and concerns instantly, social media is the best
          way
          to do so. Your good customer service staff will be in vain if they can’t attend to the customers
          conveniently
          and quickly as possible. Avantcy social media marketing in Boston can provide you an amiable social media
          manager to entertain your potential customers.</p>
      </AccordionComponent>
      <AccordionComponent label="Cost-Efficiency">
        <p>Finding a cost-efficient agency for social media marketing in Boston is one way to market and promote your
          business on a fixed budget. Most social media platforms allow you to share content at no cost while having a
          wide audience range. With our social media marketing agency in Boston, make your contents conspicuous and
          exciting enough to stand out among tons of data online.</p>
      </AccordionComponent>
      <AccordionComponent label="Better Connectivity">
        <p>Your business needs to connect with your customers and customer-to-be no matter how frequently their
          preferences, lifestyles, and resources change. With enhanced connection, you can quickly gain your potential
          customers’ attention and eventually build their trust in your brand. Our social media marketing in Boston
          always layout every marketing plan according to your target audiences’ preferences.</p>
      </AccordionComponent>
      <AccordionComponent label="Establishing Brand Awareness">
        <p>Out of tons of online businesses, the core of promoting your business is to establish brand awareness. It
          will only become possible if you let our experts take the role in shaping your potential customers’ mindset
          and influencing them to patronize your business. You can even enhance your brand awareness through our
          proper
          and effective social media marketing in Boston.</p>
      </AccordionComponent>
      <AccordionComponent label="Sales">
        <p>The more your brand gains exposure through social media sites, the better it will drive traffic to your
          company. These leads will eventually become potential customers to actual customers.</p>
      </AccordionComponent>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>ACCOUNT MANAGEMENT JUST FOR YOUR BUSINESS</h2>
            <p className={"text-align-left"}>Avantcy always ensures that each of our clients gets what they pay for,
              no matter what services are given to us. Our account management services will work closely with
              your business’ sales team to ascertain that what we will provide will fit your business needs.</p>
            <p className={"text-align-left"}>After assessing your social media account, your business’s nature, and
              how
              fast you want visible improvements, we are now ready to go and get some quality customers! Although we
              can
              help you gain new clients, we would recommend you to focus on keeping the existing ones and build their
              loyalty towards your brand.</p>
            <p className={"text-align-left"}>Better retention offers a significant return on investment as customers
              will keep on purchasing your
              services because of your good brand image. One of the secrets to attaining customer loyalty is adequate
              social media account management.</p>
          </div>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img3} id={"choosing-pic"}/>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img4} id={"choosing-pic"}/>
        </div>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>HOW ABOUT AN ADS MANAGER</h2>
          <p className={"text-align-left"}>Ads management, can be complicated for new businesses, as it involves
            intrinsic analysis and computation to prevent ads from getting oversaturated. Too many ads can lead to ad fatigue,
            where your target audience might get swamped and overwhelmed, resulting in a bombardment. It is crucial to
            let ads management experts like Avantcy’s social media marketing in Boston help you avoid this
            mistake.</p>
          <p className={"text-align-left"}>Why do you need social media ads? With social media sites’ reach, you can
            attain the following perks with an effective advertisement management agency like Avantcy’s:</p>
          <ul className={"text-align-left"}>
            <li><strong>Increase brand awareness</strong></li>
            <li><strong>Drive website traffic (lead generation)</strong></li>
            <li><strong>Gain direct sale</strong></li>
          </ul>
          <p className={"text-align-left"}>Meanwhile, social media advertisements vary depending on your budget,
            business nature, and your target customers’ preferences in social media platforms. Some of the typical
            social media ads formats are:</p>
          <ul className={"text-align-left"}>
            <li><strong>Instagram stories ads</strong></li>
            <li><strong>Facebook ads</strong></li>
            <li><strong>Collection ads (typically for e-commerce businesses)</strong></li>
            <li><strong>Static image ads</strong></li>
            <li><strong>Dynamic image ads</strong></li>
          </ul>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default smmPage;
